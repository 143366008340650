<template>
  <div>
    <h1>Clients</h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div class="row">
      <div class="col-6">
        <!-- search input -->
        <div
          class="search-wrapper"
          style="margin-bottom: 16px;"
        >
          <h3>Search Company by Client ID</h3>
          <b-form-group
            label="This is the losing client"
            label-for="searchID"
          >
            <div class="d-flex align-items-left">
              <b-form-input
                id="searchID"
                v-model="searchID"
                placeholder="Search Company by Client ID"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <hr>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'Name'"
              class="text-nowrap width-30-per font-small-4"
            >
              <span
                v-if="props.row.BranchID === 0"
                class="text-nowrap"
              >{{ props.row.Name }}</span>
              <span
                v-else-if="props.row.BranchID !== 0"
                class="text-nowrap"
              >{{ "- " + props.row.Name }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'Status'">
              <b-badge :variant="statusVariant(props.row.CRMStatus)">
                {{ props.row.CRMStatus }}
              </b-badge>
            </span>

            <!-- Column: Id -->
            <span v-else-if="props.column.field === 'ID'">
              <span class="text-nowrap">{{ props.row.ClientID + " (Client)" }}</span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <hr>
        <b-form-group
          class="required-data"
          label="Losing Branch Name"
          label-for="branchName"
        >
          <v-select
            id="branchName"
            v-model="ToLoseBranchName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="branchNameOptions"
            placeholder=""
            name="branchName"
          />
        </b-form-group>
        <hr>
        <b-form-group
          class="required-data"
          label="Losing Requestor Name"
          label-for="requesterName"
        >
          <v-select
            id="requesterName"
            v-model="ToLoseRequesterName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="toLoseRequesterNameOptions"
            placeholder=""
            name="requesterName"
          />
        </b-form-group>
      </div>

      <div
        v-if="ToLoseBranchName && ToLoseRequesterName"
        class="col-6"
      >
        <div style="height: 2.05rem" />
        <b-form-group
          label="This is the winning client"
          label-for="winSearchID"
        >
          <div class="d-flex align-items-left">
            <b-form-input
              id="winSearchID"
              v-model="winnSearchID"
              placeholder="Search Company by Client ID"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <hr>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="winRows"
          :rtl="direction"
          :search-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'Name'"
              class="text-nowrap width-30-per font-small-4"
            >
              <span
                v-if="props.row.BranchID === 0"
                class="text-nowrap"
              >{{ props.row.Name }}</span>
              <span
                v-else-if="props.row.BranchID !== 0"
                class="text-nowrap"
              >{{ "- " + props.row.Name }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'Status'">
              <b-badge :variant="statusVariant(props.row.CRMStatus)">
                {{ props.row.CRMStatus }}
              </b-badge>
            </span>

            <!-- Column: Id -->
            <span v-else-if="props.column.field === 'ID'">
              <span class="text-nowrap">{{ props.row.ClientID + " (Client)" }}</span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <hr>
        <b-form-group
          class="required-data"
          label="Winning Branch Name"
          label-for="branchName"
        >
          <v-select
            id="branchName"
            v-model="BranchName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="winnBranchNameOptions"
            placeholder=""
            name="branchName"
          />
        </b-form-group>
        <hr>
        <b-form-group
          label="Winning Requestor Name"
          label-for="requesterName"
        >
          <v-select
            id="requesterName"
            v-model="WinningRequesterName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="toWinnRequesterNameOptions"
            placeholder="Choose the requestor to assign cases. If blank, the originating requestor will be migrated to this branch."
            name="requesterName"
          />
        </b-form-group>
        <hr>
        <div class="mt-1 float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="!BranchName"
            @click="mergeClientWithPrompt()"
          >
            Move Requestor
          </b-button>
        </div>

      </div>
    </div>

    <b-modal
      id="modal-merge-client"
      ok-only
      ok-variant="danger"
      ok-title="Merge Client"
      modal-class="modal-danger"
      centered
      title="Are you sure?"
      hide-backdrop
      size="md"
      @ok="confirmMergeClient()"
    >
      <template #modal-header="">
        <div
          class="
            d-flex
            mt-1
            mb-n3
            ml-auto
            mr-auto
            text-center
            flex-column
            justify-content-center
          "
        >
          <img
            class="alert-icon"
            src="../../assets/images/icons/alert.png"
          >
          <h1 class="text-center">
            <strong>Are you sure?</strong>
          </h1>
          <div v-if="clientToWin !== null">
            You are about to merge these clients.  This action cannot be undone.
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(238,118,119,255)'"
              variant="outline-danger"
              size="md"
              @click="cancel()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(71,109,174,255)'"
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="ok()"
            >
              Merge
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBadge,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BBreadcrumb,
    BModal,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      awaitingSearch: false,
      clientToWin: null,
      columns: [
        {
          label: "Client",
          field: "Name",
        },
        {
          label: "ID",
          field: "ID",
        },
        {
          label: "Status",
          field: "CRMStatus",
        },
      ],
      rows: [],
      winRows: [],
      searchID: "",
      winnSearchID: "",
      BranchName: "",
      ToLoseBranchName: "",
      ToLoseRequesterName: "",
      WinningRequesterName: "",
      branchNameOptions: [],
      winnBranchNameOptions: [],
      toLoseRequesterNameOptions: [],
      toWinnRequesterNameOptions: [],
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
      breadcrumbItems: [
        {
          text: "Admin",
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Clients/Users",
          active: true,
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchID: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClient();
          this.awaitingSearch = false;
          this.winnSearchID = this.searchID;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
    winnSearchID: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClient(true);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
    ToLoseBranchName(val) {
      if (val) this.loadToLoseRequester(val.id)
    },
    BranchName(val) {
      if (val) this.loadToWinnRequester(val.id)
    },
  },
  methods: {
    loadToLoseRequester(branchId) {
      apiService
          .get("client/branch/"  + branchId + "/requestors?max=10000")
          .then((response) => {
            this.toLoseRequesterNameOptions = response.data.map(item => {
              return {title: item.Name, value: item.Name, id: item.ID}
            });
          });
    },
    loadToWinnRequester(branchId) {
      apiService
          .get("client/branch/"  + branchId + "/requestors?max=10000")
          .then((response) => {
            this.toWinnRequesterNameOptions = response.data.map(item => {
              return {title: item.Name, value: item.Name, id: item.ID}
            });
          });
    },
    loadClient(win = false) {
      if (win) {
          this.winRows = [];
          this.winnBranchNameOptions = [];
      } else {
          this.rows = [];
          this.branchNameOptions = [];
      }
      if ((!win && this.searchID.length) || (win && this.winnSearchID.length)) {
        apiService
            .get(
                "client/" +
                encodeURIComponent(win ? this.winnSearchID : this.searchID) +
                "/merge/detail"
            )
            .then((response) => {
                if (response && !response.data.Error) {
                    if (win) {
                        this.winRows = response.data.filter(i => i.ID === Number(this.winnSearchID))
                        this.winnBranchNameOptions = response.data.filter(client => client.BranchID !== 0).map(i => {
                            return {title: i.Name, value: i.Name, id: i.ID}
                        })
                    } else {
                        this.rows = response.data.filter(i => i.ID === Number(this.searchID))
                        this.branchNameOptions = response.data.filter(client => client.BranchID !== 0).map(i => {
                            return {title: i.Name, value: i.Name, id: i.ID}
                        })
                    }
                }
            });
      }
    },
    mergeClientWithPrompt() {
      this.$bvModal.show("modal-merge-client");
    },
    confirmMergeClient() {
      if (!this.BranchName || !this.ToLoseRequesterName) {
        return;
      }
      const postData = {
        "LosingBranchId": this.ToLoseBranchName.id,
        "LosingRequestorId": this.ToLoseRequesterName.id,
        "WinningBranchId": this.BranchName.id,
        "WinningRequestorID": this.WinningRequesterName.id || null,
      }
      apiService
          .post("client/merge-client-user", postData)
          .then((resp) => {
            if (resp) {
              this.ToLoseBranchName = null;
              this.BranchName = null;
              this.ToLoseRequesterName = null;
              this.rows = [];
              this.toLoseRequesterNameOptions = [];
              this.showToast("success", "top-center", 4000, "Client merged");
              this.loadClient();
            }
          });
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
[dir] .form-group {
  margin-bottom: 0;
}
.input-group-text {
  border-radius: 0;
}

[dir] .input-group > .form-control {
  height: 2.8em;
}
.alert-icon {
  width: 75px;
  margin: 30px auto 30px;
  display: block;
}
</style>
